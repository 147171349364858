.offers-page {


    .user-info {
        h3 {
            color: #006D33;
            font-size: 27px;
            font-weight: 700;
        }

        p {
            color: #006D33;
            font-size: 13.51px;
            font-weight: 500;
            margin-bottom: 0;

            img {
                width: 26px;
            }


        }
    }

    .offer-wrapper {
        // display: flex;
        padding: 3%;

        .offer-img-wrapper {
            position: relative;

            img {
                width: 100%;
                border-radius: 15px;
            }

            .short-offer {
                position: absolute;
                top: 11%;
                background: #006D33;
                right: 0;
                padding: 1% 3%;
                border-radius: 20px 0 0 20px;
                color: #fff;
                font-size: 16px;
                font-weight: 600;

            }

        }

        .offer-info-wrapper {
            flex: 2;
            padding: 5px;

            .offer-name {
                font-size: 16px;
                color: #000;
                margin-bottom: 3px;
                font-weight: 700;
            }

            .valid-date {
                font-size: 13px;
                color: #6B6B6B;
                margin-bottom: 0;
                font-weight: 500;
            }


            .redeem-offer-name {
                font-size: 16px;
                color: #767676;
                margin-bottom: 3px;
                font-weight: 600;
            }

            .offer-category {
                font-size: 12px;
                color: #000;
                border-bottom: 0.2px solid #000;
                ;
            }

            .redeem-offer-category {
                font-size: 12px;
                color: #767676;
                border-bottom: 1px solid #767676;
            }

            .redeem-btn {
                color: #fff;
                border: none;
                background: #006D33;
                border-radius: 3px;
                padding: 3px 8px;
                font-size: 12px;
            }
        }
    }

    .no-offer {
        background: #006D33;
        padding: 5% 3%;
        text-align: center;
        border-radius: 7px;
        margin-top: 10px;

        p {
            margin: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
    }


}