.common-carousel {
    padding: 5% 0%;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -38px !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center !important;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 0.5rem !important;
    margin-left: 15%;

    button {
        background-color: #006D33 !important;
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
    }



    .carousel-indicators [data-bs-target] {
        border-radius: 10px !important;

    }
}

.carousel-control-prev-icon {
    display: none !important;
    visibility: hidden !important;

}

.carousel-control-next-icon {
    visibility: hidden !important;
    display: none !important;
}

.carousel-item {
    padding: 0 3% !important;

    img {
        border-radius: 25px;
    }

}