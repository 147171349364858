.welcome-video-page {
    background-color: #000;
    height: 100vh;

    .welcome-video {
        width: 100%;
        height: 80vh;
    }

    .img-wrapper {
        margin-top: 8.5vh;
    }



    .button-wrapper {

        display: flex;
        justify-content: end;
        align-items: center;
        padding: 3%;
        position: fixed;
        text-align: end;
        right: 0;

        button {
            background-color: #006D33;
            color: #fff;
            width: 6vh;
            height: 6vh;
            border: none;
            border-radius: 50%;

            img {
                width: 50%;
            }

        }
    }
}