.contect-us-page {
    .back-btn-wrapper {
        padding: 5% 2%;
        display: flex;

        div {
            flex: 1;
        }

        p {
            flex: 7;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .heading {
        h1 {
            color: #006D33;
            margin-bottom: 5%;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            color: #000;
            font-weight: 600;
        }
    }

    .contect-img-wrapper {
        text-align: center;
        padding: 13% 5%;

        img {
            margin: auto;
        }
    }

    .miss-call-detail {
        p {
            font-size: 16px;
            font-weight: 600;
        }

        div {
            padding: 5% 0;

            button {
                background-color: #006D33;
                color: #fff;
                height: 50px;
                width: 100%;
                border: none;
                border-radius: 10px;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    .email-detail {
        margin-top: 3%;

        .title {
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin-bottom: 0;
        }

        .email {
            font-size: 16px;
            font-weight: 600;
            color: #767676;
        }
    }
}