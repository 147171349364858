.navbar {
    // background: #006D33;
    background: #fff;
    padding: 3% 2% !important;
    display: flex;
    // text-align: center;
    // position: relative;




    .logo-wrapper {
        flex: 1;
        display: flex;
        width: 100%;
        justify-content: start;
        padding: 0 3%;
        align-items: center;

        // text-align: center;
        .user-img {
            width: 33px;
            height: 33px;
            margin-right: 5%;
        }

        .logo-img {
            width: 75px;
        }
    }

    .hamburger-menus {
        display: flex;
        flex: 1.5;
        justify-content: end;


        .change-resto {
            background: #006D33;
            padding: 6px 10px;
            color: #fff;
            border-radius: 5px;
            font-size: 3vw;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5%;
        }

        .notifications {
            background: #006D33;
            padding: 6px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5%;


        }
    }


}