.common-toast {
    width: 100vw;
    background: transparent;
    position: fixed;
    z-index: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;

    .alert-wrapper {
        width: 90%;
        background: #035c2d;
        border-radius: 7px;
        padding: 0px 8px;

        .massege-wrapper {
            padding: 4% 2%;

            p {
                color: #fff;
                text-align: left;
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }


    }


}