.qr-success-page {
    h4 {
        color: #000;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }

    .activated-offer {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .show-qr-info {
        width: 80%;
        background: #f2f2f2;
        border-radius: 50px;
        padding: 5%;
        margin: auto;
        color: #000;
        font-weight: 600;
        font-size: 16px;

    }

    .resend-btn {
        background: transparent;
        color: #006D33;
        border: none;
        font-weight: 600;


    }
}