.redeem-now-page {
    position: relative;

    .image-wrapper {
        text-align: center;

        img {
            width: 88%;
            // height: 185px;
            /* object-fit: contain; */
            margin: auto;
            text-align: center;
            border-radius: 15px;
        }

        .short-offer {
            position: absolute;
            top: 5%;
            background: #006D33;
            right: 3.8%;
            padding: 1% 3%;
            border-radius: 20px 0 0 20px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;

        }
    }

    .restaurant-info {
        margin-top: 5%;
        margin-bottom: 5%;

        .resto-name {
            font-size: 24px;
            color: #006D33;
            margin-bottom: 0px;
            font-weight: 600;

        }

        .resto-location {
            font-size: 13px;
            color: #767676;
            border-bottom: 1px solid #767676;
            margin-bottom: 0;
        }
    }

    .offer-info {
        .offer-name {
            font-size: 28px;
            color: #000;
            font-weight: 600;
            margin-bottom: 2%;

        }

        .offer-valid {
            font-size: 16px;
            color: #000;
            font-weight: 400;
            margin-bottom: 8%;

            ul li {
                cursor: pointer;
                margin-bottom: 2%;
                list-style-type: disc;
                font-weight: 600;
                font-size: 12px;
            }

        }
    }

    .button-wrapper {
        margin-bottom: 10%;

        .redeem-btn {
            color: #fff;
            border: none;
            background: #006D33;
            border-radius: 10px;
            font-size: 18px;
            width: 100%;
            height: 50px;
            margin-bottom: 5%;
            font-weight: 600;

        }

        .share-btn {
            color: #000;
            border: 1px solid #000;
            background: transparent;
            border-radius: 10px;
            font-size: 18px;
            width: 100%;
            height: 50px;
            font-weight: 600;


        }
    }

    .terms-condition {
        p {
            margin-bottom: 0;
            font-size: 14px;
            color: #767676;
            text-align: center;
        }
    }
}