.search-city {
    border: 1px solid #dee2e6;
    padding: 1.5px 10px;
    width: 100%;
    border-radius: 5px;
    position: relative;

    .clear-btn {
        position: absolute;
        top: 15px;
        right: 10px;
    }

}

.search {
    line-height: 10px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 18px;
}

ul {
    list-style: none;
    margin-bottom: 0 !important;
}

ul li {
    cursor: pointer;
    margin-bottom: 5%;
}

hr {
    border-top: 1px solid rgba(216, 216, 216, 0.753);
}