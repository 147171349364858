 .mobile-page {
    .form-control {
       font-size: 18px !important;
    }

    .right-container {

       padding: 8%;
       background-color: #fff;

    }

    .form-wrapper {
       input {
          height: 50px;

       }
    }

    .input-margin-bottom {
       margin-bottom: 6%;
    }

    .logo-wrapper {
       text-align: center;

       //  margin-top: 20%;
       img {
          width: 100%;
       }
    }

    .instruction-wrapper {
       text-align: center;
    }

    .heading-text {
       font-size: 22px;
       color: #006D33;
       margin-bottom: 3px;
       margin-top: 8%;
       font-weight: 600;

    }

    .info-text {
       font-size: 14px;
       font-weight: 4000;
       color: #767676;
    }

    .btn-carlsberg {
       background-color: #006D33;
       color: #fff;
       width: 100%;
       height: 50px;
       border: none;
       border-radius: 5px;
       font-size: 18px;
       font-weight: 600;
    }

    .terms-wrapper {
       display: flex;
       margin: 10% 0;

       .checkbox-wrapper {
          flex: 1.5;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
             height: 22px;
             width: 22px;
             accent-color: #006D33;
          }
       }

       .terms-content-wrapper {
          flex: 9;

          p {
             margin-bottom: 0;
             font-size: 13px;
             color: #767676;

             span {
                color: #000;
                border-bottom: 1px solid #000;
                font-weight: 600;
             }
          }
       }
    }

    .mobile-number-wrapper {
       position: relative;

       button {
          background-color: #006D33;
          color: #fff;
          width: 30%;
          height: 34px;
          border: none;
          border-radius: 5px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 3%;
          font-size: 3.8vw;
       }

       .invalid-feedback {
          display: block !important;
       }
    }

    .invalid-feedback {
       display: block !important;
    }

    .resend-code {
       border-bottom: 0.4px solid #767676;
       font-size: 14px;
       color: #767676;
       margin-top: 3%;
       font-weight: 400;
    }

    .error-msg {
       color: #dc3545;
       width: 100%;
       margin-top: 0.25rem;
       font-size: .875em;
    }

 }