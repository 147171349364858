.my-profile {
    .back-btn-wrapper {
        padding: 5% 2%;
    }

    .user-info {
        text-align: center;

        .user-img {
            width: 80px;
            height: 80px;
            text-align: center;
            margin: auto;
            position: relative;

            img {
                width: 80px;
                height: 80px;
                margin: auto;
                border-radius: 50%;
            }

            .pencial-img {
                position: absolute;
                top: 45px;
                right: -6px;
                width: 25px;
                height: 25px;
            }
        }



        .user-name {
            margin-top: 5%;

            .name {
                font-size: 20px;
                color: #000;
                margin: 0;
                font-weight: 600;
            }

            .number {
                font-size: 16px;
                color: #000;
                font-weight: 500;
            }
        }
    }

    .right-container {

        padding: 8%;
        background-color: #fff;

    }

    .form-wrapper {
        input {
            height: 50px;
        }
    }

    .logo-wrapper {
        text-align: center;
        margin-top: 20%;
    }

    .instruction-wrapper {
        text-align: center;
    }

    .heading-text {
        font-size: 18px;
        font-weight: 5000;
        color: #000;
        margin-bottom: 3px;
    }

    .info-text {
        font-size: 14px;
        font-weight: 4000;
        color: #767676;
    }

    .btn-carlsberg {
        background-color: #006D33;
        color: #fff;
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
    }

    .invalid-feedback {
        display: block;
    }

    select {
        height: 50px;
    }


}