.restaurant-code-page {
    .right-container {

        padding: 8%;
        background-color: #fff;

    }

    .form-wrapper {
        input {
            height: 50px;
        }
    }

    .logo-wrapper {
        text-align: center;

        // margin-top: 20%;
        img {
            width: 100%;
        }
    }

    .instruction-wrapper {
        text-align: center;
        margin-top: 10%;
    }

    .heading-text {
        font-size: 28px;
        font-weight: 600;
        color: #000;
        margin-bottom: 3px;
    }

    .info-text {
        font-size: 14px;
        font-weight: 4000;
        color: #767676;
    }

    .btn-carlsberg {
        background-color: #006D33;
        color: #fff;
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;

    }

    .form-group {

        label {
            font-size: 14px;
            color: #767676;
            margin-bottom: 1.5%;
            font-weight: 500;
        }
    }

    .offer-info {
        padding: 7% 0 2%;

        p {
            width: 70%;
            margin-bottom: 0;
            margin: auto;
            font-size: 18px;
            color: #000;
            text-align: center;
            font-weight: 570;

        }
    }

    select {
        height: 50px;
    }

    .form-control {
        font-size: 18px;
    }


}