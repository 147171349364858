.user-profile {
    background: #006D33;
    height: 100vh;

    .back-btn-wrapper {
        padding: 5% 2%;
    }

    .user-info {
        text-align: center;

        .user-img {
            width: 80px;
            height: 80px;
            text-align: center;
            margin: auto;
            position: relative;

            img {
                width: 80px;
                height: 80px;
                margin: auto;
                border-radius: 50%;

            }

            .pencial-img {
                position: absolute;
                top: 45px;
                right: -6px;
                width: 25px;
                height: 25px;
            }
        }



        .user-name {
            margin-top: 5%;

            .name {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
                margin-bottom: 0;
            }

            .number {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
            }
        }
    }

    .list {
        padding: 10% 4%;

        .list-item {
            display: flex;
            margin-bottom: 15px;

            p {
                flex: 9;
                margin-bottom: 0;
                font-size: 16px;
                color: #fff;
                font-weight: 500;
            }

            img {
                flex: 1;
            }
        }
    }
}