body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/FontsFree-Net-Proxima-Nova-Sbold.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/Proxima\ Nova\ Black.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/Proxima\ Nova\ Bold.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/Proxima\ Nova\ Extrabold.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/Proxima\ Nova\ Font.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/Proxima\ Nova\ Thin.otf');
}
@font-face {
  font-family: proxima nova;
  src: url('../src/assets/fonts/ProximaNova-Regular.otf');
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span,
div,
a {
    font-family: 'proxima nova';
}