.about-us-page {
    .back-btn-wrapper {
        padding: 5% 2%;
        display: flex;

        div {
            flex: 1;
        }

        p {
            flex: 7;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
        }
    }

}