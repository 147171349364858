.common-alert {
    height: 100vh;
    width: 100vw;
    background: transparent;
    position: fixed;
    z-index: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .alert-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        background: #035c2d;
        flex-direction: column;
        border-radius: 7px;
        padding: 5px;

        .massege-wrapper {
            padding: 5% 2%;

            p {
                color: #fff;
                text-align: center;
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }

        .button-wrapper {
            display: flex;
            text-align: center;
            width: 100%;

            div {
                flex: 1;
                border-top: 0.5px solid #fff;

                button {
                    padding: 5% 0;
                    width: 100%;
                    border: none;
                    background: transparent;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 550;
                }
            }

            .right-border {
                border-right: 0.5px solid #fff;
            }
        }
    }


}