.redeem-offers-page {
    .back-btn-wrapper {
        padding: 5% 2%;
        display: flex;

        div {
            flex: 1;
        }

        p {
            flex: 7;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .tab-wrapper {
        display: flex;



        .tab-selected {
            p {
                color: #006D33;
                border-bottom: 1.5px solid #006D33;
                font-size: 16px;
                text-align: center;
                font-weight: 600;
                padding: 12px;
            }

            flex: 1;
        }

        .tab-unselected {
            p {
                color: #000;
                border-bottom: none;
                font-size: 16px;
                text-align: center;
                font-weight: 600;
                padding: 12px;
            }


            flex: 1;

        }
    }

    .offer-wrapper {
        display: flex;
        padding: 2% 3%;
        border-bottom: 1px solid #767676;

        .offer-img-wrapper {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100px;
                padding: 3px;
                border-radius: 14px;
                height: 100px;
                object-fit: contain;
            }

        }

        .offer-info-wrapper {
            flex: 2;
            padding: 5px;

            .offer-name {
                font-size: 16px;
                color: #767676;
                margin-bottom: 3px;
                font-weight: 600;
            }

            .redeem-offer-name {
                font-size: 16px;
                color: #767676;
                margin-bottom: 3px;
                font-weight: 600;
            }



            .offer-valid-data {
                font-size: 12px;
                color: #767676;
                font-weight: 500;
                margin-bottom: 1%;

            }

            .offer-category {
                font-size: 12px;
                color: #000;
                font-weight: 500;
                border-bottom: 0.5px solid #767676;
            }

            .redeem-offer-category {
                font-size: 12px;
                color: #767676;
                border-bottom: 1px solid #767676;
            }

            .redeem-btn {
                color: #fff;
                border: none;
                background: #006D33;
                border-radius: 3px;
                padding: 3px 8px;
                font-size: 12px;
            }
        }
    }

    .no-offer {
        background: #006D33;
        padding: 5% 3%;
        text-align: center;
        border-radius: 7px;
        margin-top: 10px;

        p {
            margin: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .no-remption {


        p {
            color: #006D33;
            font-size: 22px;
            text-align: center;
            margin-bottom: 0;
            font-weight: 600;
        }
    }
}