.otp-page {
    .right-container {

        padding: 8%;
        background-color: #fff;

    }

    .form-wrapper {
        input {
            height: 50px;
        }
    }

    .logo-wrapper {
        text-align: center;
        margin-top: 20%;
    }

    .instruction-wrapper {
        text-align: center;
    }

    .heading-text {
        font-size: 18px;
        font-weight: 5000;
        color: #000;
        margin-bottom: 3px;
    }

    .info-text {
        font-size: 14px;
        font-weight: 4000;
        color: #767676;
    }

    .btn-carlsberg {
        background-color: #006D33;
        color: #fff;
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 5px;
    }

    .resend-btn {
        background: transparent;
        color: #006D33;
        border: none;

    }
}