.common-header {
    background: #f7f7f7;
    padding: 5% 3%;
    text-align: center;
    position: relative;

    img {
        position: absolute;
        right: 3%;
        top: 25%;


    }

    h3 {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 600;
    }
}